import { type ExposeStimulus, StimulusControllerBase } from './base_controller'
import { DOMEmit, DOMListen, IsHTMLElement } from '../features/dom_utils'
import ClipsApi from '@api/ClipsApi'
import { handleEditorStyles } from '../features/editor/styles'
import { useTemplateVersionStore } from '@stores/generic/templateVersion.store'
import { getActivePinia } from 'pinia'

interface SyncResponse {
  agreement_html?: string
}
export interface ToggleSplitController
  extends ExposeStimulus<typeof ToggleSplitController> {}
export class ToggleSplitController extends StimulusControllerBase {
  static values = {
    clipId: Number
  } as const

  connect() {
    const editor = document.querySelector<HTMLElement>('.avv-editor')!
    this.handleArrows(editor)
    DOMListen('document-conditions-update', () => {
      this.handleArrows(editor)
    })
  }
  moveForward(e: MouseEvent) {
    this.move(1, e)
  }

  moveBack(e: MouseEvent) {
    this.move(-1, e)
  }

  move(direction: -1 | 1, e: MouseEvent) {
    if (
      e.target instanceof HTMLElement &&
      e.target.classList.contains('disabled')
    )
      return
    const currentEditor = document.querySelector(
      "[id*='q-editor']:not(.hidden)"
    )!
    const availableEditors = currentEditor.parentElement!.querySelectorAll(
      "[id*='q-editor'][data-active='true'], [id*='q-editor']:not(.hidden)"
    )
    const availableEditorIds = Array.from(availableEditors).map((e) => e.id)
    const otherEditorId =
      availableEditorIds[
        availableEditorIds.indexOf(currentEditor.id) + direction
      ]
    const otherEditor = document.getElementById(otherEditorId)
    if (
      !currentEditor ||
      !otherEditor ||
      !otherEditor.classList.contains('avv-editor')
    ) {
      return
    }
    currentEditor.classList.toggle('hidden')
    otherEditor.classList.toggle('hidden')
    this.handleArrows(otherEditor)

    const templateVersionStore = useTemplateVersionStore(getActivePinia())
    templateVersionStore
      .hydrateById(+otherEditor.dataset.templateVersionId!, ['docx_settings'])
      .then(() => {
        return handleEditorStyles(
          templateVersionStore,
          otherEditor.querySelector<HTMLElement>('.avv-container')!
        )
      })
      .catch((e) => {
        console.error(e)
      })
  }

  handleArrows(editor: HTMLElement) {
    const forwardArrow = editor.querySelector('.arrow-forward')
    const backArrow = editor.querySelector('.arrow-back')
    if (!backArrow || !forwardArrow) return
    const availableEditors = editor.parentElement!.querySelectorAll(
      "[id*='q-editor'][data-active='true']"
    )
    const availableEditorIds = Array.from(availableEditors).map((e) => e.id)
    const forwardAvailable =
      availableEditorIds.indexOf(editor.id) < availableEditorIds.length - 1
    const backAvailable = availableEditorIds.indexOf(editor.id) > 0
    forwardArrow.classList[forwardAvailable ? 'remove' : 'add'](
      'opacity-50',
      'disabled'
    )
    backArrow.classList[backAvailable ? 'remove' : 'add'](
      'opacity-50',
      'disabled'
    )
  }

  async sync(e: MouseEvent) {
    if (!IsHTMLElement(e.target)) return
    const button = e.target.closest('#sync-button')!
    if (!IsHTMLElement(button)) return
    const documentID = +button.dataset.documentId!
    try {
      const data = await ClipsApi.sync<SyncResponse>({
        params: { id: this.clipIdValue },
        data: { document_id: documentID }
      })
      if (data.agreement_html)
        DOMEmit('agreement_html:update', {
          html: data.agreement_html,
          documentID
        })
    } catch (e) {
      avv_dialog({
        snackMessage: 'Failed to synchronize document',
        snackStyle: 'error'
      })
    }
  }
}

export default ToggleSplitController
