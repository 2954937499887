import ExportApi from '@api/TemplateVersions/ExportApi'
import type { ExposeStimulus } from './base_controller'
import BulkActionsController from './bulk_actions_controller'

export interface TemplateBulkActionsController
  extends ExposeStimulus<typeof TemplateBulkActionsController> {}
export class TemplateBulkActionsController extends BulkActionsController {
  static targets = ['folderIdInput'] as const

  addToFolder(event) {
    const folderId = event.currentTarget.dataset.folderId
    this.folderIdInputTarget.value = folderId
    this.submitForm('add_to_folder')
  }

  removeFromFolder() {
    this.submitForm('remove_from_folder')
  }

  gsubText() {
    this.submitForm('gsub_text')
  }

  exportAvvoka() {
    const selectedInputs = Array.from<HTMLInputElement>(
      document.querySelectorAll(
        'input[type="checkbox"][name="template_ids[]"]:checked'
      )
    )
    const templateVersionIds = selectedInputs.map(
      (input) => input.dataset.templateVersionId
    )

    window.avv_download(ExportApi.create.path(), {
      template_version_ids: templateVersionIds,
      formats: 'document_avvoka'
    })
  }

  importAvvoka(event: PointerEvent) {
    event.preventDefault()

    const target = event.target as HTMLElement
    const element = target.closest('[data-profile]') as HTMLAnchorElement

    avv_upload({
      title: localizeText('template.docx_upload.title'),
      description: localizeText('template.docx_upload.desc'),
      path: '/template_versions/import',
      data: {
        profile_id: element.dataset.profile,
        folder_id: element.dataset.folderId
      },
      accept: ['.zip', '.avvoka', '.docx'],
      multiple: true
    }).then(() => {
      setTimeout(() => window.location.reload(), 2000)
    })
  }
}

export default TemplateBulkActionsController
